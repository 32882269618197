import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ThemeContext } from 'styled-components';
import { Label } from 'components/shared/Input/styles';
import { ErrorMessage } from 'components/shared';
import { BODY2 } from '@tuunetech/tuune-components';
import {
  SelectContainer,
  SelectBox,
  InputBox,
  OptionsList,
  OptionItem,
  StyledInput,
  StyledIcon,
  SelectedValue,
  HiddenSelect,
} from './styles';

export type Option = {
  value: string;
  label: string | ReactNode;
  textLabel?: string;
};

interface SelectProps {
  options: Option[];
  value?: string;
  placeholder?: string;
  onChange: (value: Option) => void;
  label?: string;
  searchable?: boolean;
  name: string;
  error?: string;
}

const SelectComponent: ForwardRefRenderFunction<
  HTMLSelectElement,
  SelectProps
> = (
  {
    options,
    value,
    placeholder,
    onChange,
    label,
    searchable = false,
    name,
    error,
  },
  ref,
) => {
  const themeContext = useContext(ThemeContext);
  const inputRef = useRef() as RefObject<HTMLInputElement>;
  const panelRef = useRef() as RefObject<HTMLDivElement>;
  const [isSearchInFocus, setIsSearchInFocus] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQ, setSearchQ] = useState('');
  const [selectedValue, setSelectedValue] = useState(
    options.find(option => option.value === value),
  );

  const isSearchAllowed = useMemo(() => {
    // search allowed only if the label is string
    // or for ReactNode label textLabel is provided
    const option = options[0];
    return !(
      option?.label &&
      typeof option?.label !== 'string' &&
      searchable &&
      !option.textLabel
    );
  }, [options, searchable]);

  const searchableLabel = useMemo(() => {
    const option = options[0];
    return typeof option?.label !== 'string' ? 'textLabel' : 'label';
  }, [searchable, options]);

  useEffect(() => {
    // check if developer tries to use searchable incorrectly
    if (!isSearchAllowed) {
      throw new Error(
        'Select does not currently accept complex searchable options. Use regular string as a label or set searchable to false.',
      );
    }
  }, [isSearchAllowed]);

  const handleSearchQChange = useCallback(e => {
    const search = e.target.value;
    setSearchQ(search);
  }, []);

  useEffect(() => {
    if (searchable) {
      setFilteredOptions(
        options.filter(option => {
          const optionLabel = option[searchableLabel];
          return (
            optionLabel &&
            typeof optionLabel === 'string' &&
            (optionLabel as string)
              .toLocaleLowerCase()
              .includes(searchQ.toLocaleLowerCase())
          );
        }),
      );
    }
  }, [searchable, options, searchQ, searchableLabel]);

  const handleOptionClicked = useCallback(
    selectedOption => {
      if (searchable) {
        setSearchQ('');
      }
      onChange(selectedOption);
      setSelectedValue(selectedOption);
      setIsExpanded(false);
    },
    [onChange, searchable],
  );

  const handleSelectClicked = useCallback(() => {
    setIsExpanded(true);
    if (searchable && inputRef.current) {
      setIsSearchInFocus(true);
      inputRef.current.focus();
    }
  }, [isExpanded, searchable]);

  useEffect(() => {
    if (searchable && isSearchInFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchable, isSearchInFocus]);

  const handleInputBlur = useCallback(() => {
    setIsSearchInFocus(false);
  }, []);

  const handlePanelBlur = useCallback(
    e => {
      const blurredElement = e.target;
      const panel = e.currentTarget;

      if (blurredElement !== panel && panelRef.current) {
        panelRef.current.focus();
      }
      if (blurredElement === panel && !isSearchInFocus) {
        setIsExpanded(false);
        if (
          filteredOptions.length === 1 &&
          (filteredOptions[0][
            searchableLabel
          ] as string).toLocaleLowerCase() === searchQ.toLocaleLowerCase()
        ) {
          onChange(filteredOptions[0]);
          setSelectedValue(filteredOptions[0]);
        }
        setSearchQ('');
      }
    },
    [isSearchInFocus, filteredOptions, searchQ, searchableLabel],
  );

  useEffect(() => {
    if (!isExpanded) {
      setSearchQ('');
    }
  }, [isExpanded]);

  return (
    <SelectContainer>
      {label && <Label>{label}</Label>}
      <SelectBox
        expanded={isExpanded}
        tabIndex={0}
        onBlur={handlePanelBlur}
        ref={panelRef}
        errorState={!!error}
      >
        <InputBox expanded={isExpanded}>
          <StyledInput
            disabled={!searchable}
            visible={searchable && isSearchInFocus}
            placeholder={placeholder}
            value={searchQ}
            onChange={handleSearchQChange}
            onFocus={() => {
              setIsSearchInFocus(true);
            }}
            onMouseLeave={() => {
              setIsSearchInFocus(false);
            }}
            onBlur={handleInputBlur}
            ref={inputRef}
          />
          <SelectedValue
            visible={!isSearchInFocus}
            isPlaceholder={
              isExpanded ? !searchQ : !selectedValue?.label && !!placeholder
            }
            onClick={handleSelectClicked}
          >
            {isExpanded
              ? searchQ || placeholder
              : selectedValue?.label || placeholder}
          </SelectedValue>
          <StyledIcon
            icon={isExpanded ? 'arrowUp' : 'arrowDown'}
            size={13}
            color={themeContext.palette.border.main}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </InputBox>
        <OptionsList isExpanded={isExpanded} errorState={!!error}>
          {filteredOptions.map(({ label, value }) => (
            <OptionItem
              key={value}
              selected={selectedValue?.value === value}
              onClick={() => {
                handleOptionClicked({ label, value });
              }}
            >
              {typeof label === 'string' ? <BODY2>{label}</BODY2> : label}
            </OptionItem>
          ))}
        </OptionsList>
        <HiddenSelect name={name} ref={ref} value={value}>
          <option value={undefined}>{undefined}</option>
          {filteredOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </HiddenSelect>
      </SelectBox>
      {error && <ErrorMessage message={error} />}
    </SelectContainer>
  );
};

const Select = forwardRef(SelectComponent);
export { Select };

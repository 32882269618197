import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Message, AlertIcon } from './styles';

type ErrorMessageProps = { message: string };
export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Message>
      <AlertIcon
        icon="alert"
        color={themeContext.palette.error.main}
        size={15}
      />
      {message}
    </Message>
  );
};

import React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { htmlSerializer, titleSerializer } from './htmlSerializer';
import { Title, SmallTitle, Wrapper } from './styles';

export const TitleParagraphsGroup: React.FC<{
  title?: RichTextBlock[];
  paragraphs?: RichTextBlock[];
  smallTitle?: boolean;
  alignLeft?: boolean;
}> = ({ title, paragraphs, smallTitle = false, alignLeft = false }) => {
  const isEmptyPar =
    paragraphs?.length === 0 ||
    (paragraphs?.length == 1 && !paragraphs[0].text);

  return (
    <Wrapper $alignLeft={alignLeft}>
      {title && smallTitle ? (
        <SmallTitle>
          <RichText render={title} htmlSerializer={titleSerializer} />
        </SmallTitle>
      ) : (
        <Title>
          <RichText render={title} htmlSerializer={titleSerializer} />
        </Title>
      )}
      {!isEmptyPar && (
        <RichText render={paragraphs} htmlSerializer={htmlSerializer} />
      )}
    </Wrapper>
  );
};

import styled from 'styled-components';
import { Icon } from '@tuunetech/tuune-components';

type SelectProps = {
  expanded: boolean;
};

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

type SelectBoxProps = {
  errorState: boolean;
  expanded: boolean;
};

export const SelectBox = styled.div<SelectBoxProps>`
  width: 100%;
  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid;
  border-color: ${({ errorState, theme }) =>
    errorState ? theme.palette.error.main : theme.palette.border.main};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  outline: none;
  border-bottom: ${({ expanded }) => (expanded ? 'none' : '')};
  border-bottom-left-radius: ${({ expanded }) => (expanded ? '0' : '')};
  border-bottom-right-radius: ${({ expanded }) => (expanded ? '0' : '')};
`;

export const InputBox = styled.div<SelectProps>`
  margin: 0 10px;
  border-bottom: ${({ expanded, theme }) =>
    expanded ? `1px solid ${theme.palette.border.main}` : 'none'};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledInput = styled.input<{ visible: boolean }>`
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.common.white};
  border: none;

  color: black;
  padding: 0 8px;
  outline: none;
  font-size: 18px;

  display: ${({ visible }) => (visible ? 'block' : 'none')};

  ::placeholder {
    color: ${({ theme }) => theme.palette.common.grey[400]};
  }
`;

type SelectedValueProps = {
  isPlaceholder?: boolean;
  visible?: boolean;
};

export const SelectedValue = styled.div<SelectedValueProps>`
  align-items: center;
  min-height: 50px;
  font-size: 18px;
  width: 100%;
  padding: 11px 8px;
  box-sizing: border-box;
  color: ${({ isPlaceholder, theme }) =>
    isPlaceholder ? theme.palette.common.grey[400] : 'inherit'};
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
`;

export const StyledIcon = styled(Icon)`
  margin: 19px 16px 0;
  cursor: pointer;
  align-self: start;
`;

type OptionsListProps = {
  isExpanded: boolean;
  errorState: boolean;
};
export const OptionsList = styled.div<OptionsListProps>`
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  width: calc(100% + 2px);
  max-height: 366px;
  min-height: 10px;
  overflow: auto;

  position: absolute;
  top: 100%;
  left: -1px;
  z-index: 100;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid;
  border-color: ${({ errorState, theme }) =>
    errorState ? theme.palette.error.main : theme.palette.border.main};
  border-radius: 10px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 10px;
`;

export const OptionItem = styled.div<{
  selected: boolean;
}>`
  width: 100%;
  padding: 20px 18px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.palette.background.light : 'inherit'};

  :hover {
    background-color: ${({ theme }) => theme.palette.background.light};
  }
`;

export const HiddenSelect = styled.select`
  width: 0;
  height: 0;
  visibility: hidden;
  border: none;
`;

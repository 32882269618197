import React, {
  InputHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import { ErrorMessage } from 'components/shared';
import { InputContainer, StyledInput, Label } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder?: string;
  label?: string;
  error?: string;
}

const InputComponent: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, placeholder, label, error, ...props },
  ref,
) => {
  return (
    <InputContainer>
      {label && <Label htmlFor={name}>{label}</Label>}
      <StyledInput
        id={name}
        name={name}
        placeholder={placeholder}
        ref={ref}
        errorState={!!error}
        {...props}
      />
      {error && <ErrorMessage message={error} />}
    </InputContainer>
  );
};

const Input = forwardRef(InputComponent);
export { Input };

import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const StyledInput = styled.input<{
  errorState: boolean;
}>`
  height: 50px;
  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
  color: black;
  padding: 0 18px;
  outline: none;
  width: 100%;
  transition: 0.2s;

  border-color: ${({ errorState, theme }) =>
    errorState ? theme.palette.error.main : theme.palette.border.main};

  ::placeholder {
    color: ${({ theme }) => theme.palette.common.grey[400]};
  }

  :focus {
    border-color: ${({ errorState, theme }) =>
      errorState ? theme.palette.error.main : theme.palette.border.dark};
  }
`;

export const Label = styled.label`
  margin-bottom: 10px;
`;

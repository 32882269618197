import styled from 'styled-components';
import { BODY2, Button } from '@tuunetech/tuune-components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  ${BODY2} + ${BODY2} {
    margin-top: 8px;
  }

  ${BODY2} {
    :last-of-type {
      margin-bottom: 24px;
    }
  }

  ${Button} {
    margin-top: auto;
  }
`;

export const Avatar = styled.div`
  margin-bottom: 24px;
  max-width: 160px;
  max-height: 160px;
`;

export const AvatarImage = styled(GatsbyImage)`
  border-radius: 50%;
  z-index: 2;
`;

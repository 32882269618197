import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { BODY1, BODY2, Button, H4 } from '@tuunetech/tuune-components';
import { CTAField } from 'types';
import { PersonContainer, Avatar, AvatarImage } from './styles';
import { navigate } from 'gatsby-link';
import { getUrl } from 'utils/helpers';
import { commonHtmlSerializer } from '../../slices/commonHtmlSerializer';

type PersonProps = {
  name?: string;
  image?: IGatsbyImageData;
  alt: string;
  role?: string;
  description?: RichTextBlock[];
  className?: string;
} & Partial<CTAField>;

export const Person: React.FC<PersonProps> = ({
  name,
  image,
  alt,
  role,
  description,
  className,
  button_text,
  button_link,
  button_color,
  button_variant,
}) => {
  return (
    <PersonContainer key={name} className={className}>
      {image && (
        <Avatar>
          <AvatarImage alt={alt} image={image} />
        </Avatar>
      )}
      {name && <H4>{name}</H4>}
      {role && image ? (
        <BODY2 paragraph>{role}</BODY2>
      ) : (
        role && <BODY1 paragraph>{role}</BODY1>
      )}
      {description && (
        <RichText render={description} htmlSerializer={commonHtmlSerializer} />
      )}
      {button_text && button_link?.url && (
        <Button
          color={button_color}
          variant={button_variant}
          onClick={() => navigate(getUrl(button_link.url))}
        >
          {button_text}
        </Button>
      )}
    </PersonContainer>
  );
};

import styled, { css } from 'styled-components';
import { BODY1, H2, H3, Button } from '@tuunetech/tuune-components';

export const Wrapper = styled.div<{ $alignLeft: boolean }>`
  ${({ theme }) => theme.breakpoints.up('md')} {
    :not(.MuiTypography-alignLeft) {
      text-align: ${({ $alignLeft }) => ($alignLeft ? 'left' : 'center')};
    }
  }

  & + ${Button} {
    margin-top: 36px;
  }
`;

export const Paragraph = styled(BODY1)`
  & + & {
    margin-top: 16px;
  }
`;

const titleCss = css`
  white-space: pre-wrap;

  & + ${Paragraph} {
    margin-top: 16px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    & + ${Paragraph} {
      margin-top: 32px;
    }
  }
`;

export const Title = styled(H2)`
  ${titleCss};
`;

export const SmallTitle = styled(H3)`
  ${titleCss};
`;

import { EcommerceProduct } from './types';

export const LANDING_MAIN_CTA_CLICK = 'landing banner CTA click';
export const LANDING_SIGN_UP_SUBMIT = 'early access submit';
export const PAGE_VIEW = 'page view';
export const CTA_CLICK = 'cta click';
export const FAQ_ITEM_OPEN = 'FAQ item opened';
export const CONTENT_TAB_ITEM_CLICK = 'tab item click';

export enum ENHANCED_ECOMMERCE_EVENT {
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
}

export const ENHANCED_ECOMMERCE_CONSTANTS = {
  brandName: 'Tuune',
  currencyCode: 'USD',
};

export const ECOMMERCE_BIRTH_CONTROL_PRODUCT: EcommerceProduct = {
  item_name: 'Birth Control',
  item_brand: ENHANCED_ECOMMERCE_CONSTANTS.brandName,
  currency: ENHANCED_ECOMMERCE_CONSTANTS.currencyCode,
  price: 29,
  quantity: 1,
};

export const ECOMMERCE_HORMONE_TEST_PRODUCT: EcommerceProduct = {
  item_name: 'Hormone Test',
  item_brand: ENHANCED_ECOMMERCE_CONSTANTS.brandName,
  currency: ENHANCED_ECOMMERCE_CONSTANTS.currencyCode,
  price: 179,
  quantity: 1,
};

export const ECOMMERCE_HORMONAL_WELLBEING_PRODUCT: EcommerceProduct = {
  item_name: 'Hormonal Wellbeing',
  item_brand: ENHANCED_ECOMMERCE_CONSTANTS.brandName,
  currency: ENHANCED_ECOMMERCE_CONSTANTS.currencyCode,
  price: 199,
  quantity: 1,
};

const SignupType = {
  birthControl: 'birth-control',
  hormoneTest: 'hormone-test',
  birthControlAndHormoneTest: 'hormonal-wellbeing',
};

const SIGNUP_TYPE_MAPPING = {
  [SignupType.birthControl]: ECOMMERCE_BIRTH_CONTROL_PRODUCT,
  [SignupType.hormoneTest]: ECOMMERCE_HORMONE_TEST_PRODUCT,
  [SignupType.birthControlAndHormoneTest]: ECOMMERCE_HORMONAL_WELLBEING_PRODUCT,
};

export const getProductByAppRedirectUrl = (
  redirectUrl: string,
): EcommerceProduct | null => {
  //const signupType = new URLSearchParams(redirectUrl).get('st');
  //const parsedUrl = new URL(redirectUrl);
  const signupType = new URL(redirectUrl).searchParams.get('st');

  if (
    signupType &&
    // Check if the URL search param is a valid SignupType
    Object.values(SignupType).some(type => type === signupType)
  ) {
    return SIGNUP_TYPE_MAPPING[signupType];
  } else {
    return null;
  }
};

import styled from 'styled-components';
import { Icon } from '@tuunetech/tuune-components';

export const AlertIcon = styled(Icon)`
  margin-right: 10px;
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  margin: 5px 21px 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

import React, { ReactNode } from 'react';
import { Elements, HTMLSerializer } from 'prismic-reactjs';
import { getUrl } from 'utils/helpers';
import { Paragraph } from './styles';
import { Link } from '../Link';

export const htmlSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key,
) => {
  switch (type) {
    case Elements.paragraph:
      return <Paragraph key={key}>{children}</Paragraph>;
    case Elements.hyperlink:
      return (
        <Link
          key={key}
          component="span"
          variant="body1"
          color="inherit"
          to={getUrl(element.data.url)}
          target={element.data.target}
        >
          {children}
        </Link>
      );
  }
};

export const titleSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key,
) => {
  switch (type) {
    case Elements.paragraph:
    case Elements.heading1:
    case Elements.heading2:
      return <React.Fragment key={key}>{children}</React.Fragment>;
  }
};
